*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h1,h2,h3 {
    color: rgb(55, 55, 55);
}

body {
    font-family: 'Lato', sans-serif;
}

h3, h4 {
    font-weight: 400;
    color: rgb(100,100,100);
}

.App {
    transition: all 0.5s ease;
}

.library-active {
    margin-left: 30%;
}

@import "./song";
@import "./player";
@import "./library";
@import "./nav";