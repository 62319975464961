.song-container{
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        width: 20%;
        border-radius: 50%;
        animation-name: spin;
        animation-duration: 10000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        pointer-events: none;
    }
    h2 {
        padding: 3rem 1rem 1rem 1rem;
    }
    h3 {
        font-size: 1rem;
    }

}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

@media screen and (max-width: 768px) {
    .song-container {
        img {
            width: 60%;;
        }
    }
}